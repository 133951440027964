<template>
    <a-layout>
        <a-layout-sider :trigger="null">
            <mymenu />
        </a-layout-sider>
        <a-layout>

            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                商户资质审核
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            lock: false,
        }
    },
    methods: {
        reconnect() {
            var that = this
            console.log('再次连接');
            if (that.lock == true) {
                return
            }
            that.lock = true;
            setTimeout(function () {
                that.websocker_aa();
                that.lock = false
            }, 500)
        },
        websocker_aa() {
            var that = this
            if ("WebSocket" in window) {

                var ws = new WebSocket("ws://localhost:8001/websocket/?token=" + localStorage.getItem('token'))
                ws.onopen = function () {
                    console.log("建立连接成功 ===> onopen", ws.readyState);
                    console.log("ws.readyState", ws.readyState);
                    // ws.send(JSON.stringify({ "token": localStorage.getItem('token') }))
                }
                ws.onmessage = function (evt) {
                    console.log(evt.data)
                    that.$message.info(evt.data)

                    that.myaxios('http://127.0.0.1:8001/suremsg/')
                }
                // ws.send("123456")

                ws.onclose = function () {
                    console.log("连接关闭");
                    that.reconnect();
                }

            } else {
                alert("您的浏览器不支持WebSocket, 请更换浏览器")
            }
        },
        send_msg() {
            if (ws.readyState === ws.OPEN) { // websock.OPEN = 1 
                // 发给后端的数据需要字符串化
                ws.send(JSON.stringify("agentData"))
            } else {
                console.log("没法出去", ws.readyState);
            }
        },
    },
    mounted() {
    },
    created() {
        this.websocker_aa();
    }
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>